<template>
	<div class="full-height">
		<h6>{{ program.name }}</h6>
		<div
			class="pa-10 bg-white width-50"
		>
			<table class="table   td-left">
				<col width="120px">
				<tbody>
				<tr
					v-if="user.roleId == 'master'"
				>
					<th>노출 여부</th>
					<td>
						<button
							class="pa-5-10 "
							:class="item.exposure == '1' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = '1'"
							:disable="is_disable"
						>노출</button>
						<button
							class="pa-5-10 mr-10"
							:class="item.exposure == '0' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = '0'"
							:disable="is_disable"
						>숨김</button>
					</td>
				</tr>
				<tr>
					<th>제목</th>
					<td>{{ item.title }}</td>
				</tr>
				<tr>
					<th>내용</th>
					<td>
						<Viewer
							v-if="item.content"
							:initialValue="item.content"
							class="pa-10 bg-gray-light box-conents-for-answer"
							style="height: 550px"
						/>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					v-if="is_master"
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
				>목록</button>
			</div>
		</div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";

export default {
	name: 'NoticeView'
	,props: ['Axios', 'user', 'codes', 'rules']
	,components: {Viewer}
	,data: function(){
		return {
			program: {
				name: '공지사항 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				title: ''
				, content: ''
				, hour: ''
				, min: ''
				, distributor: ''
				, is_use: 1
			}
			,items_distributor: [
				{ name: '총판을 선택하세요', code: ''}
			]
		}
	}
	,computed: {

		is_disable: function(){
			let is = false

			if(this.$route.name == 'NoticeBranch' && this.user.roleGroup != 'branch'){
				is = true
			}
			return is
		}

		,is_master: function(){
			let is = false

			if(this.user.roleGroup == 'admin'){
				is = true
			}

			return is
		}
	}
	, methods: {
		save: async function(){
			try{
				this.$emit('onLoading')
				this.$set(this.item, 'updateNoticeIdx', this.item.idx)

				const result = await this.Axios({
					method: 'put'
					,url: 'notice'
					,data: this.item
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getDistributor: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'common/upper'
				})
				if(result.success){
					this.items_distributor = result.data.branchList
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isCancel: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
		}
		,clear: function(){
			this.is_modal = false
		}
		,toList: function(){
			//this.$emit('push', { name: 'NoticeList', not_query: true})
			this.$router.back()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>